export function gotoLinkedIn() {
    window.location.href ="https://www.linkedin.com/in/sabhat-qureshi/";
}

export function gotoFacebook() {
    window.location.href = "https://www.facebook.com/AK47.N/?locale=nl_NL"
}

export function gotoTwitter() {
    window.location.href = "https://twitter.com/SabhatQ"
}

export function gotoInstagram() {
    window.location.href = "https://www.instagram.com/sabhatq/"
}

export function sendEmail() {
    var link = "mailto:sabhatq@gmail.com"
             + "&subject=" + encodeURIComponent("Send email to Sabhat")
             + "&body=" + encodeURIComponent("This is the body of an email")
    ;

    window.location.href = link;
}