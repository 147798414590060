import React from "react"
import Style from "./index.css"
import Picture from "./images/profile-pic.png"
import { sendEmail, gotoLinkedIn} from "./Events"


function Header() {
    return (
        <div className="header">
            <img src={Picture} className="header-pic"/>
            <h1 className="header-fullname">Sabhat Qureshi</h1>
            <h3 className="header-job-title">Dynamics 365 Technical Developer</h3>
            <h4 className="header-dynamic-website">Dynamic website</h4>
            <nav className="header-buttons">
                <button onClick={sendEmail} className="header-button-email"><i class="fa fa-envelope"> Email</i></button>
                <button onClick={gotoLinkedIn} class="btn btn-linkedin btn-lg"><i class="fa fa-linkedin fa-fw"></i> LinkedIn</button>
            </nav>
        </div>
    )
}

export default Header