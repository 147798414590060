import React from "react"
import Style from "./index.css"

function MainContent() {
    return(
        <div className="maincontent">
            <h3>About</h3>
            <p className="maincontent-text">
                I am a technical consultant with a result driven way of thinking, to deliver the objectives within the set deadlines. I then focused on the implementation of Microsoft CRM projects. With my team, I have ensured the implementation of simple to complex projects. In 2019 I started working as a technical CRM consultant.
            </p>
            <h3>Interests</h3>
            <p className="maincontent-text">
            I am a digital enthusiast with a passion for finding solutions that drive innovation and efficiency. My unique set of skills and mix of experiences across diverse sectors and industries such as Energy, Public sector and Insurance have prepared me to design sustainable solutions to all my customers. During the past years of my career, I have proven myself to be a hard-working purpose driven team player who is always committed to finding the best suitable solutions to match my customer’s needs. 
            </p>
        </div>
    )
}

export default MainContent