import React from "react"
import Style from "./index.css"
import { gotoTwitter, gotoFacebook, gotoInstagram, gotoLinkedIn } from "./Events"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

function Footer() {
    return(
        <div className="footer">
            <a onClick={gotoTwitter}><FontAwesomeIcon icon={faTwitter} className="footer-button"/></a>
            <a onClick={gotoFacebook}><FontAwesomeIcon icon={faFacebook} className="footer-button"/></a>
            <a onClick={gotoInstagram}><FontAwesomeIcon icon={faInstagram} className="footer-button"/></a>
            <a onClick={gotoLinkedIn}><FontAwesomeIcon icon={faLinkedin} className="footer-button"/></a>
        </div>    
    )
}

export default Footer
