import React from "react"
import ReactDOM from "react-dom"
import Style from "./index.css"
import App from "./App"

function Page() {
    return(
        <App/>
    )
}

ReactDOM.render(<Page />, document.getElementById("root"))